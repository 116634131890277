<template>
    <v-container>
        <v-row>
            <v-col cols="6" md="4">
                <a @click="$route.path != '/' ? $router.push('/') : null"><img src="logo.png" height="80px"/></a>
            </v-col>
            <v-col md="4" class="mt-3 d-none d-md-block">
                <v-text-field
                    v-model="search"
                    rounded outlined
                    placeholder="Search Product xx"
                    @keydown="searchProd"
                ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="mt-3 text-right">
                <account v-if="!authToken" class="d-inline-block mr-3"/>
                <cart class="d-inline-block"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import Account from '../ShopParts/Account.vue'
import Cart from '../ShopParts/Cart.vue'
export default {
  components: { Cart, Account },
    name: 'Header1',
    data: () => ({
        search: null
    }),
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
    methods: {
        searchProd(){
            this.$store.commit("searchTerm", this.search)
            this.$route.path != '/' ? this.$router.push('/products') : null
        },
    }
}
</script>