<template>
    <div>
        <v-btn small elevation="2" color="light-green lighten-2" @click="add" :disabled="disabled" class="mr-1"> 
            <v-icon>mdi-basket-outline</v-icon>&nbsp; Add to Cart
        </v-btn> 
        <v-btn small fab elevation="2" color="secondary" @click="view" :disabled="disabled" v-if="show"> 
            <v-icon>mdi-eye</v-icon>
        </v-btn>        
    </div>
</template>

<script>
export default {
    name: 'ActionBtns1',
    props:[
        'disabled',
        'show',
    ],
    methods:{
        view (){
            this.$emit('toView', 'product')
        },
        add (){
            this.$emit('toCart', 'product')
        },
    }
}
</script>

<style>

</style>