<template>
    <v-row>
        <v-col>
            <a @click="$router.push('/account')">
                <v-icon color="black" class="text-h3">mdi-account-outline</v-icon>
                <span class="light-green--text font-weight-bold ml-2 d-none d-sm-inline">Account</span>
            </a>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'Account',
    data: () => ({
    }),
}
</script>

<style>

</style>