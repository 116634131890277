import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { path: '/products', name: 'Products', component: () => import('../views/Products.vue') },
  { path: '/orders', name: 'Orders', component: () => import('../views/Order.vue') },
  { path: '/cart', name: 'Cart', component: () => import('../views/Cart.vue') },
  { path: '/checkout', name: 'Checkout', component: () => import('../views/Checkout.vue') },
  { path: '/account', name: 'Account', component: () => import('../views/Account.vue') },
  { path: '/logout', name: 'Logout', component: () => import('../components/Sections/Logout.vue') },
  { path: '*', name: '404', component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  routes
})

export default router
