<template>
  <v-card height="250px" elevation="0">
    <v-footer
      v-bind="localAttrs"
      :padless="padless"
    >
      <v-card
        flat
        tile
        width="100%"
        class="light-green lighten-4"
      >
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="3" v-for="(feature,i) in features" :key="i">
                            <service-feature-1 :title="feature.title" :icon="feature.icon"/>
                        </v-col>
                    </v-row>
                    </v-card-text>

                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                    <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="(contact,i) in contacts" :key="i">
                            <contact-info-1 :title="contact.title" :address="contact.address" :icon="contact.icon"/>
                        </v-col>
                    </v-row>
                    </v-card-text>

                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </v-container>
      </v-card>
      <v-card
        flat
        tile
        width="100%"
        class="light-green lighten-2"
      >
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <v-card-text>
                    Copyright &copy; {{ new Date().getFullYear() }} — <strong>Farm 360 - Shop</strong>
                    </v-card-text>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                    <v-card-text>
                    <img src="/assets/pay.png" height="30px"/>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-container>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
import ContactInfo1 from '../ShopParts/ContactInfo1.vue'
import ServiceFeature1 from '../ShopParts/ServiceFeature1.vue'
export default {
  components: { ServiceFeature1, ContactInfo1 },
    name: 'Footer1',  
    data: () => ({
      padless: true,
      variant: 'default',
      features: [
          {title: 'Fast delivery', icon:'mdi-truck-delivery'},
          {title: 'Fresh quality products', icon:'mdi-food-turkey'},
          {title: 'Order tracking', icon:'mdi-radar'},
          {title: 'Quick customer support', icon:'mdi-face-agent'},
      ],
      contacts: [
          {title: 'Address', address: 'Km 8, Otta-Idiroko Road, Ogun State, Nigeria', icon:'mdi-map-marker'},
          {title: 'Phone', address: '+234 01 227 9020', icon:'mdi-phone'},
          {title: 'Email', address: 'info @farm360.org', icon:'mdi-email'}
      ],
    }), 
    computed: {
      localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      },
    },
}
</script>

<style>

</style>