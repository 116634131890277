<template>
  <v-row>
      <v-col cols="12">
          <slider-1/>
      </v-col>
      <v-col cols="12">
          <our-products/>
      </v-col>
      <v-col cols="12">
          <dealof-day/>
      </v-col>
  </v-row>
</template>

<script>
import DealofDay from '../components/Sections/DealofDay.vue'
import OurProducts from '../components/Sections/OurProducts.vue'
import Slider1 from '../components/Sliders/Slider1.vue'

  export default {
  components: { Slider1, OurProducts, DealofDay },
    name: 'Home',
  }
</script>
