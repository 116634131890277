<template>
    <v-badge
        :content="cart.length"
        :value="cart.length"
        color="green"
        overlap
        
      >
        <v-icon color="black" @click="$router.push('/cart')" class="text-h3">mdi-basket-outline</v-icon>  
    </v-badge>
</template>

<script>
import {mapState} from "vuex";
export default {
    name: 'Cart',
    data: () => ({
    }),
    computed : {
        ...mapState({
            cart : state => state.cart
        }),
    }
}
</script>

<style>

</style>