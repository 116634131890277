<template>
    <v-app>
        <nav-1/>
        <header-1/>
        <nav-2/>
        <v-main>
            <router-view/>
        </v-main>
        <back-2-top/>
        <footer-1/>
    </v-app>
</template>

<script>
import Back2Top from './components/Buttons/Back2Top.vue';
import Footer1 from './components/Footers/Footer1.vue';
import Header1 from './components/Headers/Header1.vue';
import Nav1 from './components/Navs/Nav1.vue';
import Nav2 from './components/Navs/Nav2.vue';

export default {
  components: { Nav1, Header1, Nav2, Footer1, Back2Top },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
