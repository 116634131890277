<template>
    <v-chip
      :color="color"
    >
      {{value}}
    </v-chip>
</template>

<script>
export default {
    name: 'Label1',
    data: () => ({
      //
    }),
    props:[
        'color',
        'value'
    ]
}
</script>

<style scoped>

</style>