<template>
    <span>
        <v-btn
        elevation="0"
        x-large
        fab
        color="light-green lighten-1"
        >
            <v-icon color="black" class="text-h4">
                {{icon}}
            </v-icon>
        </v-btn>
        <span class="black--text ml-2">{{title}}</span>
    </span>
</template>

<script>
export default {
    name: 'ServiceFeature1',
    props:[
        'title',
        'icon',
    ],
}
</script>
