import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        products: [],
        cart: [],
        authToken: null,
        recipient: {},
        searchTerm: null
    },
    mutations: {
        products: (state, value) => {
            state.products = value
            localStorage.products = JSON.stringify(state.products);
        },
        addCartItem(state, item) {
            item.qty = 1;
            let alreadyExists = false
            state.cart.forEach(record => {
                if(item.product_id == record.product_id){
                    record.qty = parseFloat(record.qty) + item.qty
                    alreadyExists = true
                }
            });
            if (alreadyExists == false) {
                state.cart.push(item);
            }
            localStorage.cart = JSON.stringify(state.cart);
        },
        updateCartItem(state, updatedItem) {
            state.cart = state.cart.map((cartItem) => {
              if (cartItem.id == updatedItem.id) {
                return updatedItem;
              }
      
              return cartItem;
            });
        },
        removeCartItem(state, item) {
            state.cart = state.cart.filter((cartItem) => {
                return cartItem.product_id != item.product_id;
            });
            return localStorage.cart = JSON.stringify(state.cart);
        },
        emptyCart(state) {
            state.cart = []
            return localStorage.cart = JSON.stringify(state.cart);
        },
        initializeStore(state){
            if (localStorage.getItem('products')) {
                state.products = JSON.parse(localStorage.getItem('products'));
            }
            if (localStorage.getItem('cart')) {
                state.cart = JSON.parse(localStorage.getItem('cart'));
            }
            if (localStorage.getItem('recipient')) {
                state.recipient = JSON.parse(localStorage.getItem('recipient'));
            }
            if (sessionStorage.getItem('authToken')) {
                state.authToken = JSON.parse(sessionStorage.getItem('authToken'));
            }
        },
        authToken: (state, value) => {
            state.authToken = value
            sessionStorage.authToken = JSON.stringify(state.authToken);
        },
        recipient: (state, value) => {
            state.recipient = value
            localStorage.recipient = JSON.stringify(state.recipient);
        },
        searchTerm: (state, value) => {
            state.searchTerm = value
        },
        searchProducts(state){
            state.products.filter((prod) => {
                if (prod.name.includes(state.searchTerm) || prod.description.includes(state.searchTerm)) {
                    return true
                }
            });
        }
    },
    actions: {
    },
    modules: {
    }
})
