<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="text-center text-h4">
                <h3>Our Products</h3>
                <br>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-for="(product, i) in products" :key="i">
                <product-card :product="product"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import ProductCard from '../ShopParts/ProductCard.vue'
export default {
  components: { ProductCard },
    name: 'OurProducts',
    data: () => ({
      //
    }),
    computed : {
        ...mapState({
            products : state => state.products,
            authToken : state => state.authToken,
        }),
    },
    created() {
        this.fetchProductsApi()
    },
    methods : {
        fetchProductsApi() {
            axios.get(process.env.VUE_APP_BASEURL+'/products?status=active',
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$store.commit("products", response.data.data)           
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error. Try after some time!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        }
    }
}
</script>

<style>

</style>