<template>
    <v-btn color="light-green lighten-1" class="black--text" rounded @click="clicked">
        {{text}} <v-icon v-if="icon">{{icon}}</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'Button1', 
    data: () => ({
        //
    }),
    props:[
        'icon',
        'text',
    ],
    methods:{
        clicked (){
            this.$emit('clicked', true)
        },
    }
}
</script>

<style scoped>

</style>