<template>
  <v-carousel v-model="model" cycle hide-delimiters>
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    >
        <v-sheet
        height="100%"
        class="mask"
        >
            <v-row
            class="fill-height px-10"
            align="center"
            justify="center"
            >
            <div class="text-h4">
                <v-chip><h2 :class="'red--text'">{{item.text}}</h2></v-chip>
            </div>
            </v-row>
        </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
    name: 'Slider',
    data: () => ({
      model: 0,
      items: [
          {src: '/assets/slider4.jpg', text: 'Shop on Farm360.org', align: 'center'},
          {src: '/assets/slider2.jpg', text: 'Shop on Farm360.org', align: 'center'},
          {src: '/assets/slider3.jpg', text: 'Shop on Farm360.org', align: 'center'},
        ],
    }),
}
</script>

<style scoped>
.mask{
    background: rgb(177, 108, 108, 0.1);
}
</style>