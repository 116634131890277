import Vue from 'vue';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(VueLoading,{
    // props
    color: 'red',
    loader: 'spinner',
    backgroundColor:'grey'
});
