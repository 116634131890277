<template>
    <router-link class="menu" :to="to"><slot></slot> 
        <v-icon class="body-1 mr-5" color="green">mdi-chevron-right</v-icon>
    </router-link>
</template>

<script>
export default {
    name: 'Menu1',
    data: () => ({
      //
    }),
    props:[
        'to'
    ]
}
</script>

<style scoped>
.menu{
    text-decoration: unset !important;
    font-weight: 700;
    letter-spacing: 1.6px;
    color: black
}
.menu:hover{
    color: orange
}
</style>