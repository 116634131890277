import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import toast from './plugins/toastification'
import VueLoading from './plugins/loading'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  toast,
  VueLoading,
  beforeCreate() { this.$store.commit('initializeStore');},
  render: h => h(App)
}).$mount('#app')
