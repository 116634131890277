<template>
    <v-app-bar
    dense
    flat
    dark
    max-height="50"
    >
    <marquee>Buy Fresh Farm Products in <span class="yellow--text">Wholesale</span> and Retail on Farm360 Store</marquee>
    </v-app-bar>
</template>

<script>
export default {
    name: 'Nav1'
}
</script>

<style>

</style>