<template>
    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        x-small
        color="grey"
        @click="toTop"
        >
        <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'Back2Top', 
    data: () => ({
        fab: false
    }),   
    methods: {
        onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 150
        },
        toTop () {
        this.$vuetify.goTo(0)
        }
    }   
}
</script>

<style>

</style>