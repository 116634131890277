<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-app-bar
                dense
                flat
                color="#fff"
                class="appbar"
                >
                    <menu-1 class="d-none d-sm-flex" :to="'/'">Home</menu-1>
                    <menu-1 class="d-none d-sm-flex" :to="'/products'">Shop</menu-1>
                    <menu-1 class="d-none d-sm-flex" :to="'/cart'">My Cart</menu-1>
                    <menu-1 v-if="authToken" class="d-none d-sm-flex" :to="'/orders'">My Orders</menu-1>
                    <menu-1 v-if="authToken" class="d-none d-sm-flex" :to="'/logout'">Logout</menu-1>
                    <v-app-bar-nav-icon class="d-flex d-sm-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                </v-app-bar>
                <v-navigation-drawer
                    v-model="drawer"
                    temporary
                    fixed
                >
                    <v-card elevation="0">
                        <v-card-text class="light-green lighten-2">
                        <span>Menu</span>
                        <v-btn
                            icon
                            @click="drawer = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </v-card-text>
                        <v-card-text>
                            <menu-1 class="d-block mb-3 mt-3" :to="'/'">Home</menu-1>
                            <menu-1 class="d-block mb-3" :to="'/products'">Shop</menu-1>
                            <menu-1 class="d-block mb-3" :to="'/cart'">My Cart</menu-1>
                            <menu-1 v-if="authToken" class="d-block mb-3" :to="'/orders'">My Orders</menu-1>
                            <menu-1 v-if="authToken" class="d-none d-sm-flex" :to="'/logout'">Logout</menu-1>
                        </v-card-text>
                    </v-card>
                </v-navigation-drawer>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import Menu1 from '../Menu/Menu1.vue'
export default {
  components: { Menu1 },
    name: 'Nav2',
    data: () => ({
      drawer: false,
      group: null,
    }),

    watch: {
      group () {
        this.drawer = false
      },
    },

    computed : {
        ...mapState({
            authToken : state => state.authToken,
        }),
    },
}
</script>

<style scoped>
.appbar{
    border-top: 1px solid rgb(207, 207, 207) !important
}
.outward{
    z-index: 10000000000000000000 !important;
}
</style>