<template>
  <v-card>
      <v-img
        height="200"
        :src="product.images"
        >
            <label-1 v-if="discount" :color="'yellow'" :value="'-'+discount.toFixed(0)+'%'"/>
        </v-img>

    
    <v-card-text>
        <h3 class="text-subtitle-1">
        {{product.name}}
        </h3>
      <v-row
        align="center"
        class="mx-0 mt-1"
      >
        <v-rating
            v-if="product.ratings"
            :value="product.ratings"
            color="amber"
            dense
            half-increments
            readonly
            size="14"
        ></v-rating>
      </v-row>
    </v-card-text>
    <v-card-title v-if="product.qty > 0">
        {{product.currency}} {{product.discount_price.toFixed(2)}} 
        <span v-if="product.discount_price && product.discount_price !== product.sale_price">&nbsp; <em><del>{{product.sale_price.toFixed(2)}}</del></em></span> <br>
    </v-card-title>
    <v-card-title v-else>
        <v-chip color="red" class="white--text">Out of Stock</v-chip>
    </v-card-title>
    <v-card-text>
        <action-btns-1 :disabled="product.qty < 1" :show="true" @toCart="addProductCart" @toView="viewProduct"/>
    </v-card-text>
    <product-detail-modal v-if="showDetail" :product="product" :discount="discount" @close="showDetail = false"/>
  </v-card>
</template>

<script>
import Label1 from '../Labels/Label1.vue'
import ActionBtns1 from './ActionBtns1.vue'
import ProductDetailModal from './ProductDetailModal.vue'
export default {
  components: { Label1, ActionBtns1, ProductDetailModal },
    name: 'ProductCard',
    data: () => ({
        showDetail: false
    }),

    props:[
        'product'
    ],

    computed: {
        discount(){
            let discountvalue = this.product.sale_price - this.product.discount_price
            if (discountvalue > 0) {
                return discountvalue/this.product.sale_price * 100
            }
            return null
        }
    },

    methods: {
        viewProduct(){
            this.showDetail = !this.showDetail
        },
        addProductCart(){
            this.$store.commit("addCartItem", this.product)
            this.$router.push('cart')
        },
    },
}
</script>

<style scoped>
div.v-image:hover {
    -webkit-transform: scaleX(0.9) !important;
    transform: scaleX(0.9) !important;
}
</style>