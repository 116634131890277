<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="700"
    >

      <v-card>
        <v-toolbar elevation="0"> 
          <v-toolbar-title>Product quickview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="mt-2">
          <v-row>
              <v-col cols="12" md="6">
                  <v-img
                    height="300"
                    :src="product.images"
                    >
                        <label-1 v-if="discount" :color="'yellow'" :value="'-'+discount.toFixed(0)+'%'"/>
                    </v-img>
              </v-col>
                <v-col cols="12" md="6">
                    <h4 class="text-h6">
                    {{product.name}}
                    </h4>
                    <v-row
                        align="center"
                        class="mx-0 mt-1 mb-2"
                    >
                        <v-rating
                            v-if="product.ratings"
                            :value="product.ratings"
                            color="amber"
                            dense
                            half-increments
                            readonly
                            size="14"
                        ></v-rating>
                    </v-row>
                    <div class="mb-2">
                        <small>{{product.description}}</small>
                    </div>
                    <div>
                        <h2>{{product.currency}} {{product.discount_price.toFixed(2)}} 
                        <span v-if="product.discount_price && product.discount_price !== product.sale_price" class="font-weight-light">&nbsp; <em><del>{{product.sale_price.toFixed(2)}}</del></em></span> </h2>
                    </div>
                    <div class="mt-5 mb-3">
                        <span v-if="product.qty > 0" class="purple--text">{{product.qty}} {{product.unit}} left</span>
                    </div>
                    <div class="mt-2">
                         <action-btns-1 @toCart="addProductCart"/>
                    </div>
              </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Label1 from '../Labels/Label1.vue'
import ActionBtns1 from './ActionBtns1.vue'
export default {
  components: { Label1, ActionBtns1 },
    name: 'ProductDetailModal',
    data () {
      return {
        dialog: true,
      }
    },
    props:[
        'product',
        'discount'
    ],

    methods:{
        close (){
            this.$emit('close')
        },
        addProductCart(){
            this.$store.commit("addCartItem", this.product)
            this.$router.push('cart')
        },
    }

}
</script>

<style>

</style>