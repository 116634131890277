<template>
    <v-row>
        <v-col cols="2">
            <v-icon color="light-green" class="text-h4">
                {{icon}}
            </v-icon>
        </v-col>
        <v-col cols="10">
          <h4 class="font-text-bold">{{title}}</h4>
          <small v-html="address"></small>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ContactInfo1',
    props:[
        'title',
        'address',
        'icon',
    ],
}
</script>
