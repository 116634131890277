<template>
  <v-parallax
    height="350"
    src="/assets/dealbanner.jpg"
  >
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1>Deal Of The Day!</h1>
                <p>Checkout of shop for the latest deal of the day</p>
                <button-1 :text="'See All Deals'" class="d-block" @clicked="$router.push('/products')"/>            
            </v-col>
        </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import Button1 from '../Buttons/Button1.vue'
export default {
  components: { Button1 },
    name: 'DealofDay'
}
</script>

<style>

</style>